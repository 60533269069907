import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IAssumption, IGroupedAssumption } from "./assumption.types";

// execute group data for list view

//=============================== Assumption List ===============================

export const FETCH_ASSUMPTION_LIST_PROGRESS = "FETCH_ASSUMPTION_LIST_PROGRESS";
export const FETCH_ASSUMPTION_LIST_SUCCESS = "FETCH_ASSUMPTION_LIST_SUCCESS";
export const FETCH_ASSUMPTION_LIST_FAILED = "FETCH_ASSUMPTION_LIST_FAILED";

export const fetchAssumptionListProgress = () =>
  action(FETCH_ASSUMPTION_LIST_PROGRESS);
export const fetchAssumptionListSuccess = (
  data: (IGroupedAssumption | IAssumption)[],
  totalRecords: number,
) => action(FETCH_ASSUMPTION_LIST_SUCCESS, { data, totalRecords });
export const fetchAssumptionListFailed = () =>
  action(FETCH_ASSUMPTION_LIST_FAILED);

export const fetchAssumptionListAsync =
  (
    // queryParams: IQueryParams,
    country_uuid: string,
    // countryName: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchAssumptionListProgress());
      const res = await api.get(
        `/assumption/get-assumption?country_uuid=${country_uuid}`,
      );
      const data: IAssumption[] = res.data.result;

      // const country: IAssumption[] = data.map(assumption => assumption.country_name);
      // console.log("country name:",country);

      const totalRecords = res.data.totalRecords;

      console.log(totalRecords, "records");

      dispatch(fetchAssumptionListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAssumptionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//============assumption variable
export const fetchAssumptionVariableListAsync =
  (countryName: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAssumptionListProgress());
      const res = await api.get(
        `/assumption/get-assumption${countryName.length > 0 ? "&country_name=" + countryName : ""}`,
      );

      const data: IAssumption[] = res.data.result;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchAssumptionListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAssumptionListFailed());
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message ||
            "Failed to fetch assumption variables",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Assumption ===============================

export const upsertAssumptionAsync =
  (
    data: IAssumption,
    onCallback: (isSuccess: boolean, assumption?: IAssumption) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, rowId, ...rest } = data;
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/assumption/upsert-assumption", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "assumption saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//***********************fetching single data **********************//

export const FETCH_ASSUMPTION_PROGRESS = "FETCH_ASSUMPTION_PROGRESS";
export const FETCH_ASSUMPTION_SUCCESS = "FETCH_ASSUMPTION_SUCCESS";
export const FETCH_ASSUMPTION_FAILED = "FETCH_ASSUMPTION_FAILED";

export const fetchAssumptionProgress = () => action(FETCH_ASSUMPTION_PROGRESS);
export const fetchAssumptionSuccess = (data: IAssumption) =>
  action(FETCH_ASSUMPTION_SUCCESS, { data });
export const fetchAssumptionFailed = (errorMessage: string) =>
  action(FETCH_ASSUMPTION_FAILED, { errorMessage });

export const fetchAssumptionAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchAssumptionProgress());
      const res = await api.get(
        `/assumption/get-assumption?assumption_uuid=${uuid}`,
      );
      const data: IAssumption[] = res.data.result;
      if (data.length > 0) {
        dispatch(fetchAssumptionSuccess(data[0]));
      } else {
        dispatch(fetchAssumptionFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchAssumptionFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ASSUMPTION = "CLEAR_ASSUMPTION";
export const CLEAR_ASSUMPTION_STATE = "CLEAR_ASSUMPTION_STATE";
export const clearAssumption = () => action(CLEAR_ASSUMPTION);
export const clearAssumptionState = () => action(CLEAR_ASSUMPTION_STATE);
