import { IQueryParams } from "./common.types";

export const getSearchQuery = (queryParams: IQueryParams): string => {
  const { page, rowsPerPage, status, value, columns, country_uuid } =
    queryParams;
  let query = "?";
  if (status !== "-1") {
    query += `status=${status}&`;
  }
  if (columns.length > 0 && value) {
    query += `columns=${columns}&value=${value}&`;
  }
  if (country_uuid) {
    query += `country_uuid=${country_uuid}&`;
  }

  query += `pageNo=${page}&itemPerPage=${rowsPerPage}`;
  return query;
};
