import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ICountry } from "./country.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { uploadFile } from "../../helpers/uploadFile";

//=============================== Country List ===============================

export const FETCH_COUNTRY_LIST_PROGRESS = "FETCH_COUNTRY_LIST_PROGRESS";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_COUNTRY_LIST_SUCCESS";
export const FETCH_COUNTRY_LIST_FAILED = "FETCH_COUNTRY_LIST_FAILED";

export const fetchCountryListProgress = () =>
  action(FETCH_COUNTRY_LIST_PROGRESS);
export const fetchCountryListSuccess = (
  data: ICountry[],
  totalRecords: number,
) => action(FETCH_COUNTRY_LIST_SUCCESS, { data, totalRecords });
export const fetchCountryListFailed = () => action(FETCH_COUNTRY_LIST_FAILED);

export const fetchCountryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCountryListProgress());
      const res = await api.get(
        `/country/get-country-2${searchQuery}&status=active`,
      );
      const data: ICountry[] = res.data.result;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchCountryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCountryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchAllCountryListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCountryListProgress());
      const res = await api.get(`/country/get-country-2`);
      const data: ICountry[] = res.data.result;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchCountryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCountryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Country ===============================

export const upsertCountryAsync =
  (
    data: ICountry,
    onCallback: (isSuccess: boolean, country?: ICountry) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, rowId, ...rest } = data;
    try {
      dispatch(saveLoaderProgress());

      const res = await api.post("/country/upsert-country", rest);

      dispatch(
        showMessage({
          type: "success",
          message: "Country saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_COUNTRY = "CLEAR_COUNTRY";
export const CLEAR_COUNTRY_STATE = "CLEAR_COUNTRY_STATE";
export const clearCountry = () => action(CLEAR_COUNTRY);
export const clearCountryState = () => action(CLEAR_COUNTRY_STATE);
