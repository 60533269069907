import React from "react";
import {
  IIconicMultiColumnCardColumn,
  IIconicMultiColumnCardProps,
} from "./IconicMultiColumnCard.types";
import { StandardCard } from "../Cards";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import {
  AdUnitsOutlined,
  AddToHomeScreenOutlined,
  ArticleOutlined,
  AssignmentReturnedOutlined,
  AssignmentTurnedInOutlined,
  AutoAwesomeMotionOutlined,
  NotificationImportantOutlined,
} from "@mui/icons-material";

export const IconicMultiColumnCard: React.FC<IIconicMultiColumnCardProps> = (
  props,
) => {
  const { columns, loading, sx } = props;
  const finalColumns = loading
    ? [
        { heading: "A", colSize: 2, value: "" },
        { heading: "B", colSize: 2, value: "" },
        { heading: "C", colSize: 2, value: "" },
        { heading: "D", colSize: 2, value: "" },
        { heading: "E", colSize: 2, value: "" },
        { heading: "F", colSize: 2, value: "" },
      ]
    : columns;

  const getRandomIcon = () => {
    const icons = [
      <NotificationImportantOutlined color="primary" />,
      <AdUnitsOutlined color="success" />,
      <AddToHomeScreenOutlined color="error" />,
      <ArticleOutlined color="disabled" />,
      <AssignmentReturnedOutlined color="action" />,
      <AssignmentTurnedInOutlined color="warning" />,
      <AutoAwesomeMotionOutlined />,
    ];
    const randomIndex = Math.floor(Math.random() * icons.length);
    return icons[randomIndex];
  };

  return (
    <StandardCard sx={{ mb: 2, ...sx, minHeight: 100 }}>
      <Grid container spacing={1}>
        {finalColumns.map((column) => {
          return (
            <Grid key={column.heading} item xs={12} md={column.colSize || 2}>
              <IconicMultiColumnCardColumn
                {...column}
                loading={loading}
                icon={column.icon || getRandomIcon()}
              />
            </Grid>
          );
        })}
      </Grid>
    </StandardCard>
  );
};

const modifyHeading = (heading: string) => {
  if (heading.includes("_")) {
    const modifiedHeading = heading
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return modifiedHeading.join(" ");
  } else {
    return heading;
  }
};

const IconicMultiColumnCardColumn: React.FC<
  IIconicMultiColumnCardColumn & { loading?: boolean }
> = (props) => {
  const { heading, value, icon, loading = false } = props;
  const modifiedHeading = modifyHeading(heading);
  return (
    <Box
      sx={{
        margin: "15px 0px",
        borderRight: "1px solid rgb(240, 240, 240)",
        pl: 3,
        pr: 3,
      }}
    >
      <Box display={"flex"} justifyContent="space-between">
        <Typography variant="h5" fontWeight="600">
          {loading ? (
            <Skeleton width={100} height={30} />
          ) : (
            modifiedHeading || "--"
          )}
        </Typography>
        {loading ? <Skeleton width={40} /> : icon}
      </Box>
      <Typography
        variant="body1"
        sx={{ mt: "0.5rem !important", fontSize: "0.9rem" }}
      >
        {loading ? <Skeleton width={60} /> : value}
      </Typography>
    </Box>
  );
};
