import { combineReducers } from "redux";

import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";

import { commonReducer } from "./common/commonReducer";

import { UserProfileReducer } from "./UserProfileList/userProfileReducer";

import { customizerReducer } from "./customizer/customizerReducer";

import { securityReducer } from "./security/securityReducer";

import { commentReducer } from "./comments/comment.reducer";

import { countryReducer } from "./country/countryReducer";
import { assumptionReducer } from "./assumption";
import { salaryReducer } from "./salary";
import { countryPediaReducer } from "./countrypedia";

const appReducer = combineReducers({
  userprofile: UserProfileReducer,

  customizerReducer: customizerReducer,

  message: messageReducer,
  common: commonReducer,

  security: securityReducer,

  comment: commentReducer,

  country: countryReducer,
  countrypedia: countryPediaReducer,
  assumption: assumptionReducer,
  salary: salaryReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
