import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ICountryPedia } from "./countrypedia.types";
import { uploadFile } from "../../helpers/uploadFile";

//=============================== COUNTRYPEDIA List ===============================

export const FETCH_COUNTRYPEDIA_LIST_PROGRESS =
  "FETCH_COUNTRYPEDIA_LIST_PROGRESS";
export const FETCH_COUNTRYPEDIA_LIST_SUCCESS =
  "FETCH_COUNTRYPEDIA_LIST_SUCCESS";
export const FETCH_COUNTRYPEDIA_LIST_FAILED = "FETCH_COUNTRYPEDIA_LIST_FAILED";

export const fetchCountrypediaListProgress = () =>
  action(FETCH_COUNTRYPEDIA_LIST_PROGRESS);
export const fetchCountrypediaListSuccess = (
  data: ICountryPedia[],
  totalRecords: number,
) => action(FETCH_COUNTRYPEDIA_LIST_SUCCESS, { data, totalRecords });
export const fetchCountrypediaListFailed = () =>
  action(FETCH_COUNTRYPEDIA_LIST_FAILED);

export const fetchCountrypediaListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCountrypediaListProgress());
      const res = await api.get(`/website/get-countrypedia${searchQuery}`);
      const data: ICountryPedia[] = res.data.result;

      const totalRecords = res.data.totalRecords;

      console.log(data, "records");

      dispatch(fetchCountrypediaListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCountrypediaListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert COUNTRYPEDIA ===============================

export const upsertCountrypediaAsync =
  (
    data: ICountryPedia,
    flagImage: File | null,
    mainImage: File | null,
    onCallback: (isSuccess: boolean, COUNTRYPEDIA?: ICountryPedia) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    let { create_ts, insert_ts, rowId, ...rest } = data;

    try {
      dispatch(saveLoaderProgress());

      let flagPath = data.flagurl || "";
      let imagePath = data.imageurl || "";

      // Check and upload flag image
      if (flagImage) {
        flagPath = await uploadFile(flagImage, data.flagurl || "");
      }

      // Check and upload main image
      if (mainImage) {
        imagePath = await uploadFile(mainImage, data.imageurl || "");
      }

      const res = await api.post("/website/upsert-countrypedia", {
        ...rest,
        flagurl: flagPath,
        imageurl: imagePath,
      });

      dispatch(
        showMessage({
          type: "success",
          message: "COUNTRYPEDIA saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message:
            err.response?.data?.message ||
            "Error occurred while saving COUNTRYPEDIA",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
//***********************fetching single data **********************//

export const FETCH_COUNTRYPEDIA_PROGRESS = "FETCH_COUNTRYPEDIA_PROGRESS";
export const FETCH_COUNTRYPEDIA_SUCCESS = "FETCH_COUNTRYPEDIA_SUCCESS";
export const FETCH_COUNTRYPEDIA_FAILED = "FETCH_COUNTRYPEDIA_FAILED";

export const fetchCountrypediaProgress = () =>
  action(FETCH_COUNTRYPEDIA_PROGRESS);
export const fetchCountrypediaSuccess = (data: ICountryPedia) =>
  action(FETCH_COUNTRYPEDIA_SUCCESS, { data });
export const fetchCountrypediaFailed = (errorMessage: string) =>
  action(FETCH_COUNTRYPEDIA_FAILED, { errorMessage });

export const fetchCountrypediaAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCountrypediaProgress());
      const res = await api.get(
        `/website/get-countrypedia?countrypedia_uuid=${uuid}`,
      );
      const data: ICountryPedia[] = res.data.result;
      console.log(data, "data");
      if (data.length > 0) {
        dispatch(fetchCountrypediaSuccess(data[0]));
      } else {
        dispatch(
          fetchCountrypediaFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchCountrypediaFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_COUNTRYPEDIA = "CLEAR_COUNTRYPEDIA";
export const CLEAR_COUNTRYPEDIA_STATE = "CLEAR_COUNTRYPEDIA_STATE";
export const clearCountrypedia = () => action(CLEAR_COUNTRYPEDIA);
export const clearCountrypediaState = () => action(CLEAR_COUNTRYPEDIA_STATE);
