import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ISalary } from "./salary.types";

//=============================== Salary List ===============================

export const FETCH_SALARY_LIST_PROGRESS = "FETCH_SALARY_LIST_PROGRESS";
export const FETCH_SALARY_LIST_SUCCESS = "FETCH_SALARY_LIST_SUCCESS";
export const FETCH_SALARY_LIST_FAILED = "FETCH_SALARY_LIST_FAILED";

export const fetchSalaryListProgress = () => action(FETCH_SALARY_LIST_PROGRESS);
export const fetchSalaryListSuccess = (data: ISalary[], totalRecords: number) =>
  action(FETCH_SALARY_LIST_SUCCESS, { data, totalRecords });
export const fetchSalaryListFailed = () => action(FETCH_SALARY_LIST_FAILED);

export const fetchSalaryListAsync =
  (country_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSalaryListProgress());
      const res = await api.get(
        `/country/get-country-salary-component?country_uuid=${country_uuid}`,
      );
      const data: ISalary[] = res.data.result;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchSalaryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSalaryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Salary ===============================

export const upsertSalaryAsync =
  (
    data: ISalary,
    onCallback: (isSuccess: boolean, Salary?: ISalary) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, insert_ts, rowId, ...rest } = data;
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/country/upsert-country-salary-component",
        rest,
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Salary component saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.result);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//======================== fetching single data ==============================

export const FETCH_SALARY_PROGRESS = "FETCH_SALARY_PROGRESS";
export const FETCH_SALARY_SUCCESS = "FETCH_SALARY_SUCCESS";
export const FETCH_SALARY_FAILED = "FETCH_SALARY_FAILED";

export const fetchSalaryProgress = () => action(FETCH_SALARY_PROGRESS);
export const fetchSalarySuccess = (data: ISalary) =>
  action(FETCH_SALARY_SUCCESS, { data });
export const fetchSalaryFailed = (errorMessage: string) =>
  action(FETCH_SALARY_FAILED, { errorMessage });

export const fetchSalaryAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSalaryProgress());
      const res = await api.get(
        `/country/get-country-salary-component?country_salary_component_uuid=${uuid}`,
      );
      const data: ISalary[] = res.data.result;
      if (data.length > 0) {
        dispatch(fetchSalarySuccess(data[0]));
      } else {
        dispatch(fetchSalaryFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchSalaryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_SALARY = "CLEAR_SALARY";
export const CLEAR_SALARY_STATE = "CLEAR_SALARY_STATE";
export const clearSalary = () => action(CLEAR_SALARY);
export const clearSalaryState = () => action(CLEAR_SALARY_STATE);
